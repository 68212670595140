.youtube {
    position: absolute;
    bottom: 40%;
    left: 1%;
    z-index: 100;
  
    button {
      margin-top: 10px;
      margin-left: 5px;
      background: #14141d;
      color: #4e5054;
      border: none;
      border-radius: 5px;
  
      &:hover {
        color: white;
      }
    }
  
    .player {
      display: flex;
      flex-direction: column;
    }
  }
  